
<script setup lang="ts">
    import { DataObject } from 'o365-dataobject';

    const props = defineProps<{
        dataObject: DataObject;
    }>();

    function onClick() {
        if (props.dataObject.state.isRowCountLoaded) {
            return;
        }
        props.dataObject.recordSource.loadRowCount({ timeout: 30000 });
    }
</script>

<template>
    <a class="p-1 d-flex" @click="onClick">
        <template v-if="dataObject.state.isRowCountLoaded">
            <div class="text-black">{{ dataObject.state.rowCount }}</div>
        </template>
        <template v-else-if="dataObject.state.isRowCountLoading">
            <div class="spinner-border spinner-border-sm" />
        </template>
        <template v-else>
            <i class="bi bi-question-circle text-primary" />
        </template>
    </a>
</template>
